import { useEffect, useState } from "react";
import NewsItem from "./NewsItem";
import Navbar from "./Navbar";
import { getUserLocation } from "../../services/locationService";

// Define the types for the API response
interface Article {
  title: string;
  description: string;
  image_url: string;
  link: string;
  pubDate: string;
}

const NEWSDATA_KEY = [
  "pub_7683595d0e7b8f84438eaa4c848eafc44f304",
  "pub_77612397fc3b9b3edb4af5e36dbc8d294329d",
  "pub_77613a002679f75a13be0731733425ae4a279",
  "pub_77614cd82cc532146e34708948f7029d99b71",
  "pub_77621be6f45d63f13422db39e02d348c7b605",
  "pub_77622db1f08e59894aba11e5123b351b9f070",
  "pub_778515fa396c652e1c5a43b4aef633213a04b",
  "pub_7785280ef17eef86305b4b0dab1baeb532893",
  "pub_77855ec15af0e212fa8aa857b6cc63b470e3b",
  "pub_77867f1921156bf6017fa46b9a791be2b5735",
  "pub_77870192e1dad7eb10288bb8102a27b97d7b9"
];

// Get a random key from the array
const getRandomKey = () => NEWSDATA_KEY[Math.floor(Math.random() * NEWSDATA_KEY.length)];

const NewsBoardPagination: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [country, setCountry] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // Loading state to track the API call
  const [currentPage, setCurrentPage] = useState<number>(1); // Track the current page
  const [articlesPerPage] = useState<number>(9); // Set articles per page
  const [apiKey, setApiKey] = useState<string>(getRandomKey()); // Assume you start with some key

  useEffect(() => {
    const fetchData = async () => {
      try {
        setArticles([]);  // Clear existing articles when a new fetch starts
        setCurrentPage(1);
        setLoading(true);  // Start loading

        //const url=`https://newsdata.io/api/1/latest?country=${country}&category=${category}&apikey=${process.env.REACT_APP_NEWSDATA_KEY}`;
        let url = `https://newsdata.io/api/1/latest?apikey=${apiKey}`;

        if (category.length>0) {
          url += `&category=${category}`;
        }

        if (country.length>0) {
          url += `&country=${country}`;
  
          const response = await fetch(url);
          const data = await response.json();

          if (response.status === 200 && data.results.length>0) {
            setArticles(data.results);  // Update state with articles
          }
          else{
            setApiKey(getRandomKey());
          }
        }else{
          const location = await getUserLocation();
          let userCountry=location.country_code.toLocaleLowerCase();
          console.log(userCountry)
          if(userCountry=="vn") userCountry="vi";
          setCountry(userCountry)
        }
      } catch (error) {
        setApiKey(getRandomKey());
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);  // Stop loading after fetch is complete
      }
    };

    fetchData();
  }, [category, country, apiKey]);  // Re-run if category or country changes

  // Calculate the articles to display for the current page
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Calculate total pages
  const totalPages = Math.ceil(articles.length / articlesPerPage);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="card">
      <h2 className="text-center my-2">
        Latest <span className="badge bg-danger">News</span>
      </h2>
      <Navbar setCategory={setCategory} setCountry={setCountry} categoryDefault={category} />
      {totalPages > 0 && (
        <div className="d-flex justify-content-center mt-4 mx-2">
          <ul className="pagination flex-wrap">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {[...Array(totalPages)].map((_, index) => (
              <li
                key={index}
                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
        )
      }
      <div className="row px-3 py-2">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : currentArticles.length === 0 ? (
          <div className="text-primary">No articles available</div>
        ) : (
          currentArticles.map((news, index) => (
            <NewsItem
              key={index}
              title={news.title}
              description={news.description}
              src={news.image_url}
              url={news.link}
              publishedAt={news.pubDate}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default NewsBoardPagination;
