import React from "react";

// Define the types for the props
interface NewsItemProps {
  title: string;
  description?: string;
  src?: string;
  url: string;
  publishedAt: string;
}

const NewsItem: React.FC<NewsItemProps> = ({ title, description, src, url, publishedAt }) => {
  return (
    <div className="col-lg-4">
      <div className="card my-2">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <img
            src={src ? src : "/img/news.jpg"}
            style={{ height: "250px", width: "100%", objectFit: "cover" }}
            className="card-img-top"
            alt="news"
          />
        </a>
        <div className="card-body">
          <h5 className="card-title"><a href={url} target="_blank" rel="noopener noreferrer">{title.slice(0, 50)}</a></h5>
          <p className="card-text">
            {description ? description.slice(0, 90) : "CUSTOM DESCRIPTION FILE"}
          </p>
          <div className="d-flex justify-content-between">
            <a href={url} rel="noopener noreferrer" target="_blank" className="btn btn-primary">
              Read More
            </a>
            <span className="news-date text-muted small mt-3">{new Date(publishedAt).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
