import React, { useState, useEffect } from 'react';
import NewsList from './components/NewsList';
import './styles.css';
import NewsBoardPagination from './components/NewsData/NewsBoardPagination';
//import NewsBoard from './components/NewsAPI/NewsBoard';
//import NewsBoardPagination from './components/NewsAPI/NewsBoardPagination';

const App: React.FC = () => {
  const [language, setLanguage] = useState('en');

  return (
    <div>
      <div className="card p-2">
        <header>
          <h1>QuickNews24h</h1>
        </header>
        <main className='my-3'>
          <NewsList language={language} onLanguageChange={setLanguage} />
        </main>
      </div>
      <NewsBoardPagination />
    </div>
  );
}

export default App;